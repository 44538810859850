// src/main.ts
import { bootstrapApplication } from '@angular/platform-browser';
import { ErrorHandler, importProvidersFrom, ApplicationRef, EnvironmentInjector, isDevMode } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './app/i18n-loader';
import { ErrorHandlingService } from './app/services/error-handling.service';
import { provideRouter, Router } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

bootstrapApplication(AppComponent, {
  providers: [
    ...appConfig.providers,
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })),
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    provideRouter([]),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
}).catch(err => {
  console.error('Bootstrap error:', err);
});

// Add Firebase Messaging Service Worker registration
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Firebase Messaging Service Worker registered with scope:', registration.scope);
    })
    .catch((err) => {
      console.error('Firebase Messaging Service Worker registration failed:', err);
    });
}
