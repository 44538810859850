<nav class="navbar navbar-expand-lg navbar-light bg-light gradient-background">
  <div class="container-fluid">
    <a *ngIf="showLogo && !showHeading" class="navbar-brand" href="javascript:void(0);" (click)="gotoHome()">
      <img src="assets/images/icon/mrta_ev_taxi_logo.svg" alt="Logo" class="logo" />
    </a>

    <span *ngIf="checkShowHeading()" class="navbar-brand navbar-back text-white">
      <a (click)="gotoBackRoute()"><img src="assets/images/icon/icon-back.svg" alt="Back" class="logo" /></a> <span
        class="title">{{ textHeading }}</span>
    </span>

    <a (click)="toggleMenu()">
      <img class="icon-menu" src="assets/images/icon/icon-menu-icon-only.png" />
    </a>
  </div>
</nav>

<div class="menu-container" [class.show]="isMenuVisible" id="navbarNav">
  <div class="information-user row gradient-background">
    <div class="container">
      <div class="col-12 text-center frame_profile_user">
        <img class="profile_user" src="assets/images/passenger.svg" />
      </div>
      <div class="col-12 fullname_user text-center mt-2">
        <span>{{ 'Hi' | translate }}{{ fullname || 'Hi Guest' }}</span>
      </div>
      <div class="col-12 text-center mt-2">
        <div class="dropdown">
          <button class="btn btn-outline-white dropdown-toggle" type="button" id="languageDropdown"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="assets/images/icon/th.png" style="width: 25px;" alt="Thai Flag" class="flag-icon" /> {{
            'language_flag' | translate }}
          </button>
          <ul class="dropdown-menu ddl-language" aria-labelledby="languageDropdown">
            <li>
              <a class="dropdown-item btn-switchLanguage" href="javascript:void(0);" (click)="switchLanguage('th')">
                <img src="assets/images/icon/th.png" style="width: 25px;" alt="Thai Flag" class="flag-icon" /> ภาษาไทย
              </a>
            </li>
            <li>
              <a class="dropdown-item btn-switchLanguage" href="javascript:void(0);" (click)="switchLanguage('en')">
                <img src="assets/images/icon/en.png" style="width: 25px;" alt="English Flag" class="flag-icon" />
                English
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="menu-sub-container">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="gotoHome()">{{ 'call a taxi' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="gotoProfile()">{{ 'passenger_profile' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="gotoBookingHistory()">{{ 'booking_history' | translate
          }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="gotoCompliantHistory()">{{ 'compliant_history' |
          translate }}</a>
      </li>
    </ul>
  </div>

  <div class="mt-4 text-center" style="font-weight: 400;color:#CCC;">
    App version 1.1 , 14/09/2024 12:00
    <div style="margin-top:10px;height: 100px;overflow: scroll;overflow-x: hidden;text-align: left;">
      <ul style="list-style: none;">
        <li>
          <div class="row">
            <div class="col-4">
              18/09/2024 12:11 :
            </div>
            <div class="col-8">
              สามารถแจ้งเตือนใน Android ได้
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-4">
              16/09/2024 20:40 :
            </div>
            <div class="col-8">
              แก้ไขการแจ้งเตือนใน Android
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-4">
              14/09/2024 19:15 :
            </div>
            <div class="col-8">
              แก้ไขการ Default พิกัดปลายทาง กรณี Drag แผนที่
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <div class="col-4">
              14/09/2024 12:00 :
            </div>
            <div class="col-8">
              แก้ไขการ Default พิกัดสถานี<br>
              เพิ่ม padding-bottom ให้กดยกเลิก/โทรฉุกเฉิน
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>
